<template>
  <Sorting
    title="My Orders"
    placeholder="Order Search"
    :sorting="sorting"
    :is-created="false"
    @sorting="sortOrders"
    @search="searchOrders"
    @create="$router.push('/my-orders/new')"
  ></Sorting>


<!--  <Modal class="order-page-item-wizard-modal" v-model="showWizardModal">-->
<!--    <Wizard-->
<!--      ref="refWizard"-->
<!--      :isModal="true"-->
<!--      :isQuoting="false"-->
<!--      @alert="showAlert"-->
<!--      @closeModal="showWizardModal = false"-->
<!--      v-if="showWizardModal"-->
<!--    />-->

<!--  </Modal>-->


<!--  <OrdersCreate v-if="ordersCreateIsOpened" @close="ordersCreateIsOpened = false">-->
<!--  </OrdersCreate>-->
  <div class="orders_nav">
    <div v-for="(type, index) in statusList"
         class="orders_nav__icon"
         :class="index == activeStatus && 'orders_nav__icon--active'"
         @click="activeStatus = index"
    >
      <svg-icon :name="type.icon"/>
    </div>
  </div>
  <div class="orders_list" v-if="screen.width > 768">
    <OrdersListElement :ref="type.ref" :key="index" v-for="(type, index) in statusList" :type="type" >
    </OrdersListElement>
  </div>
  <div class="orders_list orders_list--mobile" v-if="screen.width <= 768">
    <OrdersListElement :type="statusList[activeStatus]">
    </OrdersListElement>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Sorting from "@/components/sorting/Sorting";
import OrdersListElement from "@/components/portal-orders/OrdersListElement";
import { useScreen, useGrid } from 'vue-screen'
import Modal from "@/components/item-wizard/components/elements/Modal.vue";
import Wizard from "../item-wizard/wizard";
import { notify } from "@kyvg/vue3-notification"
import _ from 'lodash'

export default ({
  components: {
    Sorting,
    OrdersListElement,
    Modal,
    Wizard
    // OrdersCreate
  },
  setup() {
    const store = useStore()
    const screen = useScreen()
    const showWizardModal = ref(false)
    const activeStatus = ref(0)
    const ref_draft = ref(null)
    const sorting = reactive([
      {
        name: 'By date',
        sort: 'by_date',
      },
      {
        name: 'By price',
        sort: 'by_price'
      },
    ])
    const statusList = computed(() => store.state.orders.statusList.filter(i => !i.hidden))
    const sortOrders = async (sort, direction) => {
        await store.commit('orders/ordersSorting', direction ? sort.sort :`-${sort.sort}`)
    }
    const searchOrders = _.debounce(async (search) => {
      store.commit('orders/ordersSearch', search)
    },300)

    return {
      sortOrders,
      searchOrders,
      statusList,
      sorting,
      activeStatus,
      showWizardModal,
      screen,
      ref_draft,
    }
  },
})
</script>

<style scoped lang="scss">
  .order-page-item-wizard-modal {
    height: calc(100% - 50px) !important;
    .modal {
      transform: translate(0, 0);
      left: 0;
      padding: 0;
      top: 0!important;
    }

    .modal-wrapper.show .modal {
      top: 0!important;
    }

    .wizard__content {
      width: 93vw;
      overflow-x: scroll;
    }

    .modal-wrapper.delete-confirmation-modal {
      display: flex!important;
      align-items: center;
      justify-content: center;
    }

    .line.example__line_width {
      transform: none;
    }

    .enter-size__overall {
      width: max-content;
    }
  }

</style>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.orders_list {
  display: flex;
  margin: 0 20px;
  justify-content: space-between;
  overflow: auto;
  &>::v-deep div:last-child {
    margin-right: 0;
  }
  &--mobile {
    display: none;
  }
}
.orders_nav {
  display: none;
}
@media (max-width: 768px) {
  .orders_list {
    margin: 0;
    display: none;
    &>::v-deep div:last-child {
      margin: 0 auto;
    }
    &--mobile {
      display: flex;
    }
  }
  .orders_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 47px;
      height: 47px;
      background: #0B3553;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-right: 1px solid #0B3553;
      color: rgba(255, 255, 255, 0.8);
      transition-duration: 0.2s;
      &:first-child {
        border-left: 1px solid #0B3553;
      }
      &--active {
        border-left: 1px solid #F1C40F!important;
        border-right: 1px solid #F1C40F;
        background: #F1C40F;
        color: #1C2833;
      }
    }
  }
}
</style>
