<template>
  <section>
      <OrdersList></OrdersList>
  </section>
</template>

<script>

import { ref } from 'vue'
import { useStore } from 'vuex'
import OrdersList from "@/components/portal-orders/OrdersList";

export default {
  components: {
    OrdersList
  },
  setup() {

  }
};
</script>

<style scoped>

</style>
